<template>
  <el-dialog
    title="Remove Location area(s)"
    :visible.sync="shouldShow"
    width="30%"
    append-to-body
    @close="closeEvent"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Location area(s)"
            prop="location_area_id"
            :rules="validateField()"
          >
            <el-select
              v-model="form.location_area_id"
              multiple
              filterable
              placeholder="Select location areas"
            >
              <el-option
                v-for="area in gardener.location_areas"
                :key="area.id"
                :label="area.name"
                :value="area.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        type="danger"
        :loading="adding"
        :disabled="form.cluster_id === null"
        @click="remove"
        >Remove</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import locationareas from "@/requests/zones/locationareas";

export default {
  name: "GardenerLocationAreaRemove",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    gardener: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        gardener_id: null,
        location_area_id: [],
      },
      locationAreaNames: [],
      adding: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.gardener_id = this.gardener.id;
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
    },
    remove() {
      this.adding = true;
      locationareas
        .gardener("remove", this.form)
        .then((response) => {
          if (response.data.status) {
            this.$emit("success", this.form.location_area_id);
            this.$message.success(response.data.message);
            this.adding = false;
            this.closeEvent();
          }
        })
        .catch((error) => {
          this.adding = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
